import React, { Component, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Tree } from 'antd';
import { formatDateByLocaleString } from '../../../assets/helper';
import "antd/dist/antd.css";

import oblax from "../../../assets/oblax";
import {
	DeliveryTimeslot, DeliverySchedule 
} from "@oblax/sdk-js";
import "../css/Delivery.css"
import { ArrayIsEmpty, isEmpty } from "../../../assets/helper";
import { Toast, EmptyStateDisclaimer } from "../../../parts";
import { UTILITIES } from "../../../assets/constants";
import { setCategories } from "../../Home/actions"
import { Tabs, TabContent } from '../../Tabs/index';

import { setProductList, setProductsMap } from "../../Products/actions/index";
import placeholder from "../../../assets/images/person-placeholder.png";
import SelectDropdown from '../../../parts/components/SelectDropdown';

const Delivery = (props) => {

	const days = {
		0: "Sunday",
		1: "Monday",
		2: "Tuesday",
		3: "Wednesday",
		4: "Thursday",
		5: "Friday",
		6: "Saturday",
	};

	const types = {
		"DAY": "Day",
		"DATE": "Date",
		"DATE_RANGE": "Date range"
	}

	const timeslotInit = {
		start_time: new Date(Date.UTC(1979, 1, 1, 1, 0, 0, 0)).toISOString(), 
		end_time: new Date(Date.UTC(1979, 1, 1, 1, 0, 0, 0)).toISOString(), 
		cap: 0
	};

	const selectedExceptionTypeInit = {
		id: 'DAY',
		name: 'Day'
	};

	const scheduleInit = {
		type: 'DAY',
		date_start: new Date().toISOString(),
		date_end: new Date().toISOString(),
		day: 1,
		repeat: false
	};

	useEffect(()=> {
		(async () => {
			let data = await oblax.orders.getTimeslots();
			setTimeSlots(data.data);
			let data1 = await oblax.orders.getDeliverySchedules();
			setSchedules(data1.data);
		})();  
	}, []);
	
	const timeFormatter = (d) => {
		let date = new Date(d);
		let h = date.getHours() - 1;
		let m = date.getMinutes();
		h = (h < 10) ? "0" + h : h;
		m = (m < 10) ? "0" + m : m;
		return `${h}:${m}`;
	};

	const dateFormatter = (de) => {
		let date = new Date(de);
		let d = date.getDate();
		let m = date.getMonth() + 1;
		let y = date.getFullYear();
		d = (d < 10) ? "0" + d : d;
		m = (m < 10) ? "0" + m : m;
		return `${y}-${m}-${d}`;
	};

	const dateFormatterTable = (de) => {
		let date = new Date(de);
		let d = date.getDate();
		let m = date.getMonth() + 1;
		let y = date.getFullYear();
		d = (d < 10) ? "0" + d : d;
		m = (m < 10) ? "0" + m : m;
		return `${d}-${m}-${y}`;
	};

	const handleAddingSchedule = (e) => {
		let d = e.target.value.split("-");
		let date = new Date(Date.UTC(Number(d[0]), Number(d[1] -1 ), Number(d[2]), 0, 0, 0, 0 )).toISOString();
		setSchedule({...schedule, [e.target.name] : date});
	}

	const onChangeCheckbox = (e) => {
		setSchedule({...schedule, [e.target.name] : e.target.checked});
	}

	// creating a schedule

	const createDeliverySchedule = async () => {
		try {
			let data = {
				type: schedule.type,
				date_start: new Date(schedule.date_start),
				date_end: new Date(schedule.date_end),
				day: Number(schedule.day),
				repeat: schedule.repeat
			};
			let s = new DeliverySchedule();
			s.loadFromJSON(data);
			let result = await oblax.orders.createDeliverySchedule(s);
			if(result.ok){
				setSchedules([...schedules, result.data]);
				setSchedule(scheduleInit);
			} else{
				if(process.env.REACT_APP_ENV !== 'prod') {
					console.log(result.status);
				}
			}
		} catch(err) {
			if(process.env.REACT_APP_ENV !== 'prod'){
				console.log(err);
			}
		}
	}

	//removing a schedule

	const removeDeliverySchedule = async (e) => {
		try {
			let result = await oblax.orders.removeDeliverySchedule(e.target.dataset.id);
			if(result.ok){
				setSchedules(schedules.filter(sc => sc.id !== e.target.dataset.id));
			} else {
				if(process.env.REACT_APP_ENV !== 'prod') {
					console.log(result.status);
				}
			}
		} catch(err) {
			if(process.env.REACT_APP_ENV !== 'prod'){
				console.log(err);
			}
		}
	}

	// updating a schedule 

	// const updateDeliverySchedule = async() => {
	// 	try {
	// 		var s = new DeliverySchedule;
	// 		s.loadFromJSON({
	// 			type: schedule.type,
	// 			date_start: new Date(schedule.date_start),
	// 			date_end: new Date(schedule.date_end),
	// 			day:Number(schedule.day),
	// 			repeat: schedule.repeat
	// 		});
	// 		let result = await oblax.orders.updateDeliverySchedule(schedule.id, s);
	// 		activateAlert("success", "You have succesfully edited a schedule");
	// 	} catch(err){
	// 		console.log(err);
	// 	}
	// }

	const [timeSlot, setTimeSlot] = useState(timeslotInit);

	const [timeSlots, setTimeSlots] = useState([]);

	const [selectedExceptionType, setSelectedExceptionType] = useState(selectedExceptionTypeInit);

	const [alert, setAlert] = useState({
		type: '',
		text: '',
		triger: false
	});

	const [deleteDeliveryTimeslotId, setdeleteDeliveryTimeslotId] = useState('');
	const [deleteAlert, setdeleteAlert] = useState(false);
	const [schedule, setSchedule] = useState(scheduleInit);
	const [schedules, setSchedules] = useState([]);

	const handleAddingTimeSlot = (e) => {
		let t = e.target.value.split(':');
		let time = new Date(Date.UTC(1970, 1, 1, Number(t[0]), Number(t[1]), 0, 0)).toISOString();
		setTimeSlot({...timeSlot, [e.target.name] : time})
	}

	const onChangeCapacity = (e) => {
		setTimeSlot({...timeSlot, [e.target.name] : e.target.value});
	}

	const removeTimeslot = async (e) => {  
		try {
			let result = await oblax.orders.removeTimeslot(e.target.dataset.id);
			if(result.ok){
				setTimeSlots(timeSlots.filter(ts =>  ts.id !== e.target.dataset.id));
			} else {
				if(process.env.REACT_APP_ENV !== 'prod') {
					console.log(result.status);
				}
			}
		} catch (err) {
			if(process.env.REACT_APP_ENV !== 'prod') {
				console.log(err);
			}
		}
	}
	
	//creating a timeslot

	const createTimeslot = async () => { 
		try {
			var s = new DeliveryTimeslot();
			s.loadFromJSON({
				start_time: new Date(timeSlot.start_time),
				end_time: new Date(timeSlot.end_time),
				cap: Number(timeSlot.cap)

			});
			let result = await oblax.orders.createTimeslot(s);
			if(result.ok) {
				setTimeSlots([...timeSlots, result.data]);
				setTimeSlot(timeslotInit);
				
			} else {
				if(process.env.REACT_APP_ENV !== 'prod') {
					console.log(result.status);
				}
			}
		} catch(err) {
			if(process.env.REACT_APP_ENV !== 'prod'){
				console.log(err);
			}
		}
		
	};

	//updating a timeslot

	// const updateTimeslot = async () => {
	// 	try {
	// 		var s = new DeliveryTimeslot();
	// 		s.loadFromJSON({
	// 			start_time: start_time,
	// 			end_time: end_time,
	// 			cap: cap, // add id to the state and when loading from json
	// 		});

	// 		let result = await oblax.orders.updateTimeslot(timeSlot.id, s)
	// 		activateAlert("success", "You have successfully edited a timeslot.");
	// 	} catch(err) {
	// 		console.log(err);
	// 	}
	// };

	//activate alert function, activation of alert box on user action
	// const activateAlert = () => {
	// 	setAlert({
	// 		type: type, 
	// 		text: text, 
	// 		triger: false
	// 	}) , () => {
	// 		setTimeout(() => {
	// 			setAlert({
	// 				type: "", 
	// 				text: "",
	// 				triger: false
	// 			})
	// 		}, UTILITIES.alertTime)
	// 	}
	// };

	//HERE ENDS THE FUNCTIONAL PART OF DELIVERY TIMESLOTS AND BEGINS THE FUNCTIONAL PART OF DELIVERY SCHEDULE

	const handleSelectChange = (v) => {
		setSelectedExceptionType(v);
		setSchedule({...schedule, type: v.id});
	};

	const handleDayChange = (e) => {
		setSchedule({
			...schedule,
			day: Number(e.target.value)
		});
	};

		return (
			<div id="delivery-categories">
				{/* ALERTS */}
				<div className="delivery-categories-container">
					<div className="content-blocks">
						<div className="content-block light">
							<div className="content-block-intro">
								<h2 className="content-block-title">
									<span className="content-block-icon structure"></span>
									Delivery timeslots
								</h2>
								<button type="button" value="add-timeslot" className="rounded-button primary primary-icon gradient orange add" onClick={createTimeslot}>Add new timeslot</button>

							</div>
							<div className="content-block-main">
								<div className="content-block-main__timeslot-form">
									<input 
										type="time"
										name="start_time"
										className={"input-field--labeled"}
										value={timeFormatter(timeSlot.start_time)}
										onChange={handleAddingTimeSlot}
									/>
									<input 
										type="time"
										name="end_time"
										className={"input-field--labeled"}
										value={timeFormatter(timeSlot.end_time)}
										onChange={handleAddingTimeSlot}
									/>
									<input 
										type="number"
										name="cap"
										className={"input-field--labeled"}
										value={timeSlot.cap}
										onChange={onChangeCapacity}
									/>
								</div>
								<div className="table-content">
									<table className="delivery-table">
										<thead className="delivery-table-head">
											<tr>
												<th>Time from</th>
												<th>Time to</th>
												<th>Capacity</th>
												<th></th>
											</tr>
										</thead>
										<tbody className="delivery-table-body">
											{timeSlots.map(ts => {
												return (
													<tr>
														<td>{timeFormatter(ts.start_time)}</td>
														<td>{timeFormatter(ts.end_time)}</td> 
														<td>{ts.cap}</td>
														<td><button onClick={removeTimeslot} data-id={ts.id} className="icon-button delete-button grad">DELETE</button></td> 
													</tr>
													
												)
											})}
										</tbody>
									</table>
								</div>
							</div>
							
						</div>
						<div className="content-block dark">
							<div className="content-block-intro">
								<h2 className="content-block-title">
									<span className="content-block-icon metadata"></span>
									Delivery dates exceptions
								</h2>
								{/* <button type="button" value="save-button" className="rounded-button primary primary-icon gradient blue save-button" onClick={createDeliverySchedule}>SAVE</button> */}
								<button type="button" value="save-button" className="rounded-button primary primary-icon gradient orange" onClick={createDeliverySchedule}>ADD NEW SCHEDULE</button>
							</div>
							<div className="content-main">
								{/* <label for="schedule" className="schedule-label">Choose a schedule:</label> */}
								<div className="content-block-main__schedule-dropdown schedule-container">
									<SelectDropdown
										value={schedule.type}
										disabled={false}
										options={[{ id: "DAY", name: "Day" }, { id: "DATE", name: "Date" }, { id: "DATE_RANGE", name: "Date Range" }]}
										labelField="name"
										valueField="id"
										handleSelectChange={handleSelectChange}
										model={selectedExceptionType}
										className={"dropdown-list"}
										/>
									{/* conditional rendering depending on the choosen value from the dropdown list */}
									{
										selectedExceptionType.id === "DAY" &&
											<div className="radio-picker-group">
												<label>
													<input type="radio" name="day" value="1" onClick={handleDayChange} checked={schedule.day === 1}/> 
													<span>Mon</span>
												</label>
												<label>
													<input type="radio" name="day" value="2" onClick={handleDayChange} checked={schedule.day === 2}/> 
													<span>Tue</span>
												</label>
												<label>
													<input type="radio" name="day" value="3" onClick={handleDayChange} checked={schedule.day === 3}/> 
													<span>Wed</span>
												</label>
												<label>
													<input type="radio" name="day" value="4" onClick={handleDayChange} checked={schedule.day === 4}/> 
													<span>Thu</span>
												</label>
												<label>
													<input type="radio" name="day" value="5" onClick={handleDayChange} checked={schedule.day === 5}/> 
													<span>Fri</span>
												</label>
												<label>
													<input type="radio" name="day" value="6" onClick={handleDayChange} checked={schedule.day === 6}/> 
													<span>Sat</span>
												</label>
												<label>
													<input type="radio" name="day" value="0" onClick={handleDayChange} checked={schedule.day === 0}/> 
													<span>Sun</span>
												</label>
											</div>
										
									}
									{
										selectedExceptionType.id === "DATE" &&
										<input 
											type="date"
											name="date_start"
											value={dateFormatter(schedule.date_start)}
											className={"input-field--labeled single-date--picker"}
											onChange={handleAddingSchedule}
											format="DD-MM-YYYY hh:mm"
										/>
									}
									{
										selectedExceptionType.id === "DATE_RANGE" && 
										<div className="double-date-picker">
											<input 
												type="date"
												name="date_start"
												value={dateFormatter(schedule.date_start)}
												className={"input-field--labeled"}
												onChange={handleAddingSchedule}
											/>
											<input 
												type="date"
												name="date_end"
												value={dateFormatter(schedule.date_end)}
												className={"input-field--labeled"}
												onChange={handleAddingSchedule}
											/>
										</div>
									}
									<div className="checkbox-grid">
										<p className="checkbox-holder">
											<input 
												type="checkbox"
												name="repeat"
												className="checkbox--labeled repeated"
												id="form-data-title"
												onChange={onChangeCheckbox}
												// value={schedule.repeat}
												value="true"
												checked={schedule.repeat} 
											/>
											<label className="check-label" for="checkbox">Repeat</label>
										</p>
									</div>

								</div>
								<div className="table-content">
									<table className="delivery-table schedule-table">
										<thead className="table-head">
											<tr>
												<th>TYPE</th>
												<th>DATE START</th>
												<th>DATE END</th>
												<th>DAY</th>
												<th>REPEAT</th>
												<th></th>
											</tr>
										</thead>
										<tbody className="delivery-table-body">
											{schedules.map(s => {
												return (
													<tr>
														<td>{types[s.type]}</td>
														<td>{types[s.type] === 'Date' || types[s.type] === 'Date range' ? dateFormatterTable(s.date_start) : null}</td>
														<td>{types[s.type] === 'Date range' ? dateFormatterTable(s.date_end) : null}</td>
														<td>{types[s.type] === 'Day' ? days[s.day] : null}</td>
														<td>{Number(s.repeat) === 1 ? 'Yes' : 'No' }</td>
														<td><button className="icon-button delete-button" data-id={s.id} onClick={removeDeliverySchedule}>DELETE</button></td>
													</tr>
												)
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>	
				</div>
			</div>
		);

}

export default Delivery;