import React, { Component } from 'react';
// import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import oblax from "../../../assets/oblax";
import { setFilterData } from "../actions";
import { DropDown, Loader, Toast, EmptyStateDisclaimer, ConfirmAlert } from "../../../parts";
import Datetime from "react-datetime";
import { ORDER_STATUSES } from '../../../assets/constants';
import { formatDate } from '../../../assets/helper';

import "../css/Orders.css"

/* VIEWS */

class Orders extends Component {
	constructor() {
		super();
		this.state = {
			loader: true,
			orders: [],
			showFilter: false,
			productSettings: '',
			deleteAlert: false,
			alert: false
		}

		this.filterChange = this.filterChange.bind(this);
		this.addNewOrder = this.addNewOrder.bind(this);
		this.ToggleFilter = this.ToggleFilter.bind(this);
		this.DatepickerChangeHandler = this.DatepickerChangeHandler.bind(this);
		this.selectedSettings = this.selectedSettings.bind(this);
		this.closeSelectSettings = this.closeSelectSettings.bind(this);
		this.deleteAlert = this.deleteAlert.bind(this);
		this.cancelDeleteAlert = this.cancelDeleteAlert.bind(this);
	}

	componentDidMount() {
		this.setState({
			filter: this.props.filter
		})
		oblax.orders.getAll()
			.then((result) => {
				if (result.ok) {
					console.log(result.data);
					this.setState({
						orders: result.data,
						loader: false
					}, () => {
						// this.props.setOrders(result.data)
					})
				} else {
					console.log("ORDERS ERROR", result.status);
				}
			})
			.catch(err => {
				console.log("ORDERS ERROR: ", err);
			})
	}

	filterChange({ target }) {
		if (target.name === "active") {
			this.setState(
				prevState => ({
					filter: {
						...prevState.filter,
						[target.name]: !prevState.filter.active
					}
				}), () => {
					if (this.state.filter.active) {
						this.props.setFilterData(this.state.filter);
					} else {
						this.setState({
							filter: {
								name: '',
								email: '',
								phone: '',
								address: '',
								date: '',
								delivery_time: '',
								status: 'accepted',
								active: false,
								invoice_id: ''
							}
						}, () => {
							this.props.setFilterData(this.state.filter);
						})

					}
				}
			)
		} else {
			this.setState(
				prevState => ({
					filter: {
						...prevState.filter,
						[target.name]: target.value
					}
				}), () => {
					this.props.setFilterData(this.state.filter);
				}
			)
		}
	}

	handleStatusSelectChange = (status) => {
		this.setState(
			prevState => ({
				filter: {
					...prevState.filter,
					status: status !== "" ? status.title : ""
				}
			}), () => {
				this.props.setFilterData(this.state.filter);
			}
		)
	}

	// DATE ON CHANGE EVENT HANDLER
	// =========================
	DatepickerChangeHandler(value) {
		this.setState(
			prevState => ({
				filter: {
					...prevState.filter,
					date: new Intl.DateTimeFormat("en-US", { year: 'numeric', day: '2-digit', month: "2-digit", hour12: false }).format(value._d)
				}
			}), () => {
				this.props.setFilterData(this.state.filter);
			}
		)
	}

	// DATE ON CHANGE EVENT HANDLER
	// =========================
	TimepickerChangeHandler(value) {
		this.setState(
			prevState => ({
				filter: {
					...prevState.filter,
					delivery_time: new Date(value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
				}
			}), () => {
				this.props.setFilterData(this.state.filter);
			}
		)
	}

	getOrderDetails(data) {
		//GET ORDERS DETAILS
		this.props.history.push(`/e-shop/orders/${data.id}`);
	}

	addNewOrder() {
		this.props.history.push("/e-shop/orders/new");
	}

	ToggleFilter() {
		this.setState(state => {
			return { showFilter: !state.showFilter };
		});
	}

	selectedSettings(e, id) {
		e.stopPropagation();

		if (this.state.productSettings !== id) {
			this.setState({
				productSettings: id
			})
		} else {
			this.setState({
				productSettings: ''
			})
		}
	}

	closeSelectSettings() {
		this.setState({
			productSettings: ''
		})
	}

	deleteAlert(e, id) {
		e.stopPropagation();

		this.setState({
			deleteAlert: true,
			selectedProductId: id
		});
	}

	cancelDeleteAlert() {
		this.setState({
			deleteAlert: false,
			selectedProductId: ''
		})
	}

	removeDate = () => {
		this.setState(
			prevState => ({
				filter: {
					...prevState.filter,
					date: ""
				}
			}), () => {
				console.log("TCL: handleStatusSelectChange -> this.state.filter", this.state.filter);
				this.props.setFilterData(this.state.filter);
			}
		)
	}

	render() {

		const FilteredOrdersList = this.state.orders.filter(item => {
			if (!item.full_name || !item.email || !item.phone || !item.delivery_address || !item.status || !item.invoice_id) {
			// if (!item.full_name || !item.email || !item.phone || !item.delivery_address || !item.delivery_address_number || !item.status || !item.invoice_id) {
				console.log(item);
				return false;
			}
			return this.props.filter.date !== ''
				? (
					item.full_name.toLowerCase().includes(this.props.filter.name.toLowerCase()) 
					&& item.email.toLowerCase().includes(this.props.filter.email) 
					&& item.phone.toLowerCase().includes(this.props.filter.phone) 
					&& item.delivery_address.toLowerCase().includes(this.props.filter.address) 
					// && item.delivery_address_number.toLowerCase().includes 
					&& item.status.toLowerCase().includes(this.props.filter.status.toLowerCase()) 
					&& (
						new Intl.DateTimeFormat("mk-MK", { year: 'numeric', day: '2-digit', month: "2-digit", hour12: false }).format(new Date(item._created)) === new Intl.DateTimeFormat("mk-MK", { year: 'numeric', day: '2-digit', month: "2-digit", hour12: false }).format(new Date(this.props.filter.date))
					)
				) : (
					item._created.includes(this.props.filter.date) 
					&& item.full_name.toLowerCase().includes(this.props.filter.name.toLowerCase()) 
					&& item.email.toLowerCase().includes(this.props.filter.email) 
					&& item.phone.toLowerCase().includes(this.props.filter.phone) 
					&& item.delivery_address.toLowerCase().includes(this.props.filter.address) 
					// && item.delivery_address_number.toLowerCase().includes 
					&& item.status.toLowerCase().includes(this.props.filter.status.toLowerCase())
				)

				// item.invoice_id.includes(this.props.filter.invoice_id) &&
				// item.invoice_id.includes(this.props.filter.invoice_id) &&
		});

		const filteredOrdersMap = {};
		FilteredOrdersList.forEach(order => {
			let date = (order._created.substring(0, 10));
			if (!filteredOrdersMap[date]) {
				filteredOrdersMap[date] = [];
			}
			filteredOrdersMap[date].push(order);
		});

		const datesArray = Object.keys(filteredOrdersMap).sort((a, b) => new Date(b) - new Date(a));

		return (
			<div id="orders" className="orders">
				{this.state.deleteAlert &&
					<ConfirmAlert
						title="Delete Order"
						message="You are attempting to delete an order from the database. Are you sure you want to proceed?"
						handleMainFunction={this.deleteProduct}
						handleSeconderyFunction={this.cancelDeleteAlert}
					/>
				}
				{/* <div className="products-search">
					<h2>Orders</h2>
					<Icon onClick={this.addNewOrder} className="add-new-product" type="plus-circle" theme="outlined" /> 
					<Input onChange={this.filterChange} name="global" value={this.state.filter} placeholder="Product Search" />
				</div> */}

				<div className="products-table-container">
					<div className="table-filter">
						<div className="filter-row main">
							<div className="filter-item main">
								<button className="circle-button light icon filter-icon" onClick={this.filterChange} name="active"></button>
							</div>
							<div className="filter-item">
								<button
									className="rounded-button primary primary-icon gradient orange new-product-button"
									onClick={this.addNewProduct}
									disabled={true}
								>
									<span className="button-text">New Order</span>
								</button>
							</div>
						</div>
						{this.props.filter.active &&
							<div className="filter-row detail">
								<p className="input-holder">
									<input
										type="text"
										className="input-field--labeled"
										id="filter-order-name"
										onChange={this.filterChange}
										name="name"
										value={this.props.filter.name}
										required
									/>
									<label className="field-label" htmlFor="filter-order-name">Order name</label>
									<span className="input-border"></span>
								</p>
								<p className="input-holder">
									<input
										type="text"
										className="input-field--labeled"
										id="filter-order-email"
										onChange={this.filterChange}
										name="email"
										value={this.props.filter.email}
										required
									/>
									<label className="field-label" htmlFor="filter-order-email">Customer e-mail</label>
									<span className="input-border"></span>
								</p>
								<p className="input-holder">
									<input
										type="text"
										className="input-field--labeled"
										id="filter-order-phone"
										onChange={this.filterChange}
										name="phone"
										value={this.props.filter.phone}
										required
									/>
									<label className="field-label" htmlFor="filter-order-phone">Customer phone</label>
									<span className="input-border"></span>
								</p>
								<p className="input-holder">
									<input
										type="text"
										className="input-field--labeled"
										id="filter-order-address"
										onChange={this.filterChange}
										name="address"
										value={this.props.filter.address}
										required
									/>
									<label className="field-label" htmlFor="filter-order-address">Customer address</label>
									<span className="input-border"></span>
								</p>
								<p className="input-holder date-holder">
									<Datetime
										formatDate={"DD/MM/YYYY"}
										inputProps={{
											readOnly: true,
											id: "filter-order-date"
										}}
										value={this.props.filter.date}
										locale={"mk"}
										timeFormat={false}
										input={true}
										disableOnClickOutside={true}
										closeOnSelect={true}
										onChange={(event) => this.DatepickerChangeHandler(event)}
									/>
									{this.props.filter.date !== '' && this.props.filter.date !== undefined &&
										<span onClick={this.removeDate}>X</span>
									}
									<label className={`field-label ${this.props.filter.date !== '' && 'active-date-label'}`} htmlFor="order-delivery-date">Delivery Date</label>
								</p>
								{/* <p className="input-holder half">
									<input
										className="input-field--labeled"
										id="order-delivery-time"
										name="delivery_time"
										type="time"
										value={"13:30"}
										onChange={this.inputChange}
										disabled={this.state.edit}
										required
									/>
									<label className="field-label" htmlFor="order-delivery-time">Delivery Time</label>
									<span className="input-error">Time is required</span>
									<span className="input-border"></span>
								</p> */}
								<DropDown
									disabled={false}
									title="Status"
									handler="title"
									list={ORDER_STATUSES}
									handleSelectChange={(event) => this.handleStatusSelectChange(event)}
									value={this.props.filter.status}
									errorMessage="None"
									error={false}
									className=""
								/>
							</div>
						}
					</div>

					<div className="products-table-wrap">
						<table className="bc-table">
							<thead className="bc-table-head">
								<tr>
									<th>Invoice Id</th>
									<th>Name</th>
									<th>E-mail</th>
									<th>Phone</th>
									<th>Address</th>
									<th>Created</th>
									<th>Time</th>
									<th>Status</th>
									<th className="pusher">pusher</th>
								</tr>
							</thead>

							<tbody className="bc-table-body">
								{this.state.loader ?
									<tr>
										<td>
											<Loader />
										</td>
									</tr> :
									FilteredOrdersList.length > 0 ?
										datesArray.map(item => {
											if (item !== (new Date().toISOString().substring(0, 10))) {
												return [
													<tr>
														<td colSpan={9}>
															<div className="date-divider">{formatDate(item)}</div>
														</td>
													</tr>,
													filteredOrdersMap[item].map(order => {
														return (
															<tr onClick={() => this.getOrderDetails(order)} className="bc-table-row" key={order.id}>
																<td className="bc-table-data"><h2 className="table-classic-data">{order.invoice_id}</h2></td>
																<td className="bc-table-data"><h2 className="product-title">{order.full_name}</h2></td>
																<td className="bc-table-data"><h2 className="table-classic-data email">{order.email}</h2></td>
																<td className="bc-table-data"><h2 className="table-classic-data">{order.phone}</h2></td>
																<td className="bc-table-data"><h2 className="table-classic-data">{order.delivery_address} {order.delivery_address_number}</h2></td>
																<td className="bc-table-data"><h2 className="table-classic-data">{formatDate(order._created)}</h2></td>
																<td className="bc-table-data"><h2 className="table-classic-data">{new Date(order._created).toLocaleTimeString()}</h2></td>
																<td className="bc-table-data"><h2 className={"table-classic-data status " + (order.status.toLowerCase())}> <span></span> {order.status}</h2></td>
																<td className="bc-table-data">
																	<button className="square-button light icon settings-icon" onClick={(event) => this.selectedSettings(event, order.id)}></button>
																	<div id="table-settings-dropdown" className={this.state.productSettings === order.id ? "opened" : ""}>
																		<ul className="dropdown-list">
																			<li>
																				<a onClick={() => this.getOrderDetails(order)}>
																					<span className="dropdown-icon edit"></span>
																					<h2 className="dropdown-title">Edit</h2>
																				</a>
																			</li>
																			<li>
																				<a onClick={(event) => this.deleteAlert(event, order.id)}>
																					<span className="dropdown-icon delete"></span>
																					<h2 className="dropdown-title">Delete</h2>
																				</a>
																			</li>
																		</ul>
																	</div>
																</td>
															</tr>
														);
													})
												];
											} else {
												return filteredOrdersMap[item].map(order => {
													return (
														<tr onClick={() => this.getOrderDetails(order)} className="bc-table-row" key={order.id}>
															<td className="bc-table-data"><h2 className="table-classic-data">{order.invoice_id}</h2></td>
															<td className="bc-table-data"><h2 className="product-title">{order.full_name}</h2></td>
															<td className="bc-table-data"><h2 className="table-classic-data email">{order.email}</h2></td>
															<td className="bc-table-data"><h2 className="table-classic-data">{order.phone}</h2></td>
															<td className="bc-table-data"><h2 className="table-classic-data">{order.delivery_address} {order.delivery_address_number}</h2></td>
															<td className="bc-table-data"><h2 className="table-classic-data">{formatDate(order._created)}</h2></td>
															<td className="bc-table-data"><h2 className="table-classic-data">{new Date(order._created).toLocaleTimeString()}</h2></td>
															<td className="bc-table-data"><h2 className={"table-classic-data status " + (order.status.toLowerCase())}> <span></span> {order.status}</h2></td>
															<td className="bc-table-data">
																<button className="square-button light icon settings-icon" onClick={(event) => this.selectedSettings(event, order.id)}></button>
																<div id="table-settings-dropdown" className={this.state.productSettings === order.id ? "opened" : ""}>
																	<ul className="dropdown-list">
																		<li>
																			<a onClick={() => this.getOrderDetails(order)}>
																				<span className="dropdown-icon edit"></span>
																				<h2 className="dropdown-title">Edit</h2>
																			</a>
																		</li>
																		<li>
																			<a onClick={(event) => this.deleteAlert(event, order.id)}>
																				<span className="dropdown-icon delete"></span>
																				<h2 className="dropdown-title">Delete</h2>
																			</a>
																		</li>
																	</ul>
																</div>
															</td>
														</tr>
													);
												})
											}
										})

										:

										<tr>
											<td colSpan="8">
												<EmptyStateDisclaimer
													message="No such info on our shuttle"
													icon="rocket"
												/>
											</td>
										</tr>
								}
							</tbody>
						</table>
					</div>
				</div>
				{/* <table className="products-table">
					<thead>
						<tr className="product-table-head">
							<td>Id</td>
							<td>Cart ID</td>
							<td>Status</td>
						</tr>
					</thead>
					<tbody className="products-table-body">
						{this.state.loader ?
							<tr>
								<td>
									<Icon type="loading" theme="outlined" />
								</td>
							</tr>
							:
							this.state.orders
								.map((item, i) =>
									<tr onClick={() => this.getOrderDetails(item)} className="bc-table-row" key={item.id}>
										<td className="bc-table-data"><h2 className="table-classic-data">{item.id !== undefined ? item.id : "EMPTY"}</h2></td>
										<td className="bc-table-data"><h2 className="table-classic-data">{item.cart_id !== undefined ? item.cart_id : "EMPTY"}</h2></td>
										<td className="bc-table-data"><h2 className="table-classic-data">{item.status !== undefined ? item.status : "EMPTY"}</h2></td>
									</tr>
								)
						}
					</tbody>
				</table> */}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		filter: state.order.filter,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setFilterData: (data) => {
			dispatch(setFilterData(data))
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders);